import './App.css';
import *  as React from 'react';

import { Link } from 'react-router-dom'

import Content from './Content';
import { HashLink, NavHashLink } from 'react-router-hash-link';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


export default function App () { 





   return(
    <>

    <Router>
      <Routes>
        <Route path="/" element={<Content />} />
      </Routes>
    </Router>

    </>

    
   )
}


