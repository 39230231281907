import './App.css';
import *  as React from 'react';
import Typewriter from "typewriter-effect";

const connectionString = "mailto:ishrat@wellokaylisten.com?subject=Let's Connect!"; 

export default function Contact() { 
    return(                
        <>
      
    <div className='written'>
          <p id="header">
            let's chitchat!
        </p>
        

                <p><a href={connectionString}>Email me</a></p>
                <p><a href="https://www.linkedin.com/in/ishrat-l/" target="_blank">LinkedIn</a><br/><br/>
                <a href="https://wellokaylisten.com/Resume.pdf" target='_blank'>Resume</a></p>
            </div></>
)
}