import styled from '@emotion/styled';
import './App.css';
import *  as React from 'react';
import Typewriter from "typewriter-effect";


export default function Games() { 
    return(                
        <>
        
    <div className='written'>
        <p id="header">
           game design and development
        </p>
                <p>Here are some selected games or game assets that I've created for the fun of it. I like to imagine what different design systems look like in different universes. These include user interfaces that I've imagined, loading screens and games that I've created.</p>

                         <h2>Games in development</h2>

                <p><a href="https://04529.github.io/halobinary/" target='_blank'>Halo: Binary</a>
                <p><blockquote>A Halo textbased fangame for a retelling of Halo: Infinite. <br/>
                <b>Current Status:</b> Wireframing and mockups!</blockquote></p></p>

                <h2>THEORETICAL games</h2>

                <p><b>Legend of Zelda: Tears of the Princess</b><br/>
                <b>Summary:</b> Instead of Tears of the Kingdom where Zelda is transported back in time, it's Link. The screens linked are a thereotical game where you play as Princess Zelda, versus Link. A departure from the classical model of the Legend of Zelda games, this is a turn-based strategy game that focuses on the intellect and tenacity of the beloved Princess versus the bravery and courage of Link. </p>

                <p><a href="https://xd.adobe.com/view/93c10d7b-ae16-46b0-8a8f-29a571652a0a-c692/?fullscreen" target="_blank">View Assets</a></p>

                <h2>Hypothetical Design Systems from Games</h2>

                <p>I <i>love</i> thinking about hypothetical design systems from different franchises. It's something that's on-going and that I collect.  Please enjoy them! I'm constantly making more!</p>

                <ul>
                    <li><a href="https://www.figma.com/file/nRrABTUsS6oH388G37NSjk/Halo-Fanwork%3A-ONI-Design-System-(Community)?type=design&node-id=0%3A1&mode=design&t=Ksx84WKVk8y6qN1m-1" target='_blank'>Office of Naval Intelligence: Halo</a></li>
                </ul>

                <h2>for giggles</h2>

                <p>Sometimes I generate assets based on what games I'm playing and how they inspire other games! Please enjoy some of the assets that I've created. 
                </p>

                <h3>Halo screens reimagined in the style of Wolfenstein</h3>

                <p>
                    <img src={require('./gameover1.png')} style={{width: '40%', marginLeft: '10px'}}/> 
                    <img src={require('./gameover2.png')} style={{width: '40%'}} />
                </p>


       
            </div></>
)
}