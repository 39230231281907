import './App.css';
import *  as React from 'react';
import Typewriter from "typewriter-effect";
import { HashLink, NavHashLink } from 'react-router-hash-link';

import {ReactComponent as Indesign} from './images/adobeindesign.svg';
import {ReactComponent as Photoshop} from './images/adobephotoshop.svg';
import {ReactComponent as XD} from './images/adobexd.svg';
import {ReactComponent as Arduino} from './images/Arduino.svg';
import {ReactComponent as CSS} from './images/CSS.svg';
import {ReactComponent as Figma} from './images/Figma.svg';
import {ReactComponent as JS} from './images/JS.svg';
import {ReactComponent as MUI} from './images/MUI.svg';
import {ReactComponent as Three} from './images/Three.svg';
import {ReactComponent as DD} from './images/DD.svg';
import {ReactComponent as HTML} from './images/HTML.svg';














export default function About() { 
    return(                
        <>
       
    <div className='written'>
         <div  id="header">
           tl;dr about me
        </div>



           <p>I'm an inter-disciplinary designer. It's not limited to web or mobile applications, but also includes a spatial element like exhibits or public spaces. Experiential design is similar  to a mueseum or an event, a concert or (perrsonally one of my favorite spaces in the world) an interactive art exibit like <a href="https://meowwolf.com/" target='blank'>MeowWolf</a>, you've experienced Experiential design. It's about taking someone on a journey or transporting someone to another place through how they interact with the world.</p>

        <p>There's a lot to what I do! My main focus at the moment is crafting <b>data narratives to help communities make decisions at the <a href="https://dt.asu.edu" target="_blank">Decision Theater at ASU</a></b>. 
        </p>



       <h2>education</h2>
       <ul>
        <li>2021 - 2023  &#x21A0; Masters of Science, Media Arts and Sciences [@] Arizona State University <br/>
        <i>Graduated with Academic Distinction</i></li><br/>
        <li>2011 - 2013 &#x21A0; Bachelors of Arts, Economics [@] University of Arizona</li>
       </ul>

       <h2>exhibits/presentations</h2>
       <ul>
        <li>December 2023 &#x21A0; Panelist // Smart Urban Gardens Workshop [@] Tempe, AZ</li>
        <li>November 2023  &#x21A0; Presentation // Importance of Data Narratives in Data Viusalization [@] 2023 ASU Data Conference</li>
        <li>November 2022  &#x21A0; Co-Artist & Class Project // ModLife: A Hyperlocal Community of the Future [@] 2022 Emerge Festival</li>
        <li>November 2022  &#x21A0; Presentation // UX in Data Visualization [@] 2022 ASU Data Conference</li>
        <li>November 2018  &#x21A0; Presentation // Effective Data Visualization Tools [@] 2018 ASU Data Conference</li>
       </ul>

       <h2>tools of my trade</h2>

       <h3>design software</h3>

       <XD/>&nbsp; 
       <Figma/>&nbsp; 
       <Photoshop/>&nbsp; 
       <Indesign/>&nbsp; 
    
       <h3>web tools</h3>

       <HTML/>&nbsp; 
       <JS/>&nbsp; 
       <CSS/>&nbsp; 
       <MUI/>&nbsp; 


       <h3>experiential</h3>
       <Arduino/>

    
       <h3>dungeon master</h3>

       <DD/>






       <h2>selected works?</h2>

       <HashLink smooth to={'/#portfolio'}   style={{textDecoration: 'none'}}>Check out my Portfolio!</HashLink>


        </div>
  </>
)
}