import './App.css'; 
import * as React from 'react'; 

import About from './About';
import Games from './Games';
import Contact from './Contact';
import Portfolio from './Portfolio';
import Home from './Home';


import { HashLink, NavHashLink } from 'react-router-hash-link';



export default function Content(){
    return(
    
<>
    <div class='nav'>
        
        <span className='navStyle'>&nbsp;</span>

        <HashLink smooth to={'/#about'}   style={{textDecoration: 'none'}}>ABOUT</HashLink>
        <span className='navStyle'>&#10006;</span> 


            <HashLink smooth to={'/#portfolio'}   style={{textDecoration: 'none'}}>PORTFOLIO</HashLink>
        <span className='navStyle'>&#10006;</span>

        <HashLink smooth to={'/#games'}   style={{textDecoration: 'none'}}>GAMES</HashLink>
        <span className='navStyle'>&#10006;</span>

            <HashLink smooth to={'/#contact'}   style={{textDecoration: 'none'}}>CONTACT</HashLink>
             <span className='navStyle'>&#10006;</span>

            <HashLink smooth to={'/#top'}   style={{textDecoration: 'none'}}>TOP</HashLink>

                    <span className='navStyle'>&nbsp;</span>


            
        </div>
        <div className='content'>
         <div id="top"><Home/></div>
         <div id="about"><About/></div>
        <div id="portfolio"><Portfolio id="portfolio"/></div>
        <div id="games"><Games/></div>
            <div id="contact"><Contact id="contact"/></div>
          
        </div>
        </>
    )
}