import './App.css';
import *  as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';








export default function Portfolio() {
  return (
    <> <div className='written'>
        <p id="header">selected works and links</p>


    <h2>featured works</h2>

 
    <Accordion style={{backgroundColor: '#FFB400'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color: '#23213e'}}/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h2>AZ CuRVE (2022)</h2>
        </AccordionSummary>
        <AccordionDetails>
        <img src={require('./Curve.jpg')} style={{width: '100%', height: '300px', objectFit: 'cover', borderRadius: '5px'}}/>
          <p><span  style={{backgroundColor: '#23213e', padding: '5px', borderRadius: '5px'}}><a href="https://curve.dtn.asu.edu/" target='_blank' id="accordian">Live Version</a></span></p>
          <p><b>Role</b>: Interaction Designer<br/>
          <b>Team</b>: Decision Theater <br/>
          <b>Partners</b>: Kyl Center for Water Policy, Piper Foundation, and Arizona Water Innovation Initiative</p>
          <blockquote>❝The Arizona Colorado River Visualization Enterprise (CuRVE) is an interactive, visualization-based tool that enables stakeholders to explore datasets and analyses regarding the impacts of Colorado River shortage in Arizona.❞</blockquote>

          <h3>problem statement</h3>

          <p>Arizona is dependent on water access to the Colorado River. Our partners with the Kyl Center had data that they wanted to visualize to answer the following questions: </p>

          <ul>
            <li>How much Colorado River water will my community lose under different hydrological scenarios?</li>
            <li>What non-Colorado River sources of water will my community use during Colorado River shortage? </li>
            <li>How will different responses to various hydrological scenarios impact my community’s water supplies? </li>
          </ul>

          <h3>interaction design</h3>

          <p><b>Crafting the Data Narrative</b><p></p>

          <b>Data Narrative</b>: A story you want your data to tell. It's beyond hierachies, but it's also about crafting a data narrative to create empathy and connection between data/stakeholders and end users. <br/>

          <p><i>the data narrative</i> </p>

          <img src={require('./curveNarrative.jpg')} style={{width: '100%'}}/></p>
            

          <b>Personas</b>

          <p><i>General Public</i>:  John Doe is a member of the Gilbert community who wants to to know what the impact of a water shortage might have on his community. He owns a house, and has a green lawn and is wondering how his HOA will respond to shortages <br/><br/>
          <i>Technical User</i>:  Mary Joe is a water manager for the city of Scottsdale. Mary has a lot of knowledge of water, water law and water terminonlogy. She is concerned  about the impact of water access to her community and how she will need plan for the future. <br/><br/>
          <i>Media</i>: Xander Doe is a reporter for a local environmental blog. Jane has some knowledge of water, water terms and no knowledge of water law. They  want to report about the state of water access in Arizona, and how the community would be impacted<br/><br/></p>

          <b>User Flows</b>

          <p><i>level one</i><br/>
          <img src={require('./curveUserFlow.png')} style={{height: '500px'}}/></p>

          <p><i>Link to Wireframes</i> </p>

        <p><span  style={{backgroundColor: '#23213e', padding: '5px', borderRadius: '5px'}}><a href="https://xd.adobe.com/view/06583adb-9bc0-4c68-8b72-dd9131003614-530b/?fullscreen" target="_blank">General Public</a></span></p>
            <p><span  style={{backgroundColor: '#23213e', padding: '5px', borderRadius: '5px'}}><a href="https://xd.adobe.com/view/6a60bc00-963c-4048-a0cd-26d9af0d0f1e-3f33/?fullscreen" target='_blank'>Technical View</a></span>
         
          
          </p>

   
        </AccordionDetails>
      </Accordion>
      <p></p>


    <Accordion style={{backgroundColor: '#FFB400'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color: '#23213e'}}/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h2>connect2test (2019)</h2>
        </AccordionSummary>
        <AccordionDetails>
          
        <img src={require('./Connect.jpg')} style={{width: '100%', height: '300px', objectFit: 'cover', borderRadius: '5px'}}/>
          <p><span  style={{backgroundColor: '#23213e', padding: '5px', borderRadius: '5px'}}><a href="https://chs.asu.edu/diagnostics-commons/connect-to-test/" target='_blank' id="accordian">Live Version</a></span></p>
          <p><b>Role</b>: UX Designer, UI Designer<br/>
          <b>Team</b>: Decision Theater <br/>
          <b>Partners</b>: College of Health Solutions, COVID-19 Commons, Rockerfeller Foundation, Project N95</p>
          <blockquote>❝Connect to Test is a new tool that helps organizations and individuals find the right COVID-19 tests for their unique needs. Developed in collaboration with When To Test and Project N95, Connect to Test will allow users to make informed and speedy COVID-19 testing decisions.❞</blockquote>

          <h3>problem statement</h3>

          <p>During the pandemic, finding Covid tests was extremely difficult. Connect2Test would walk users through a workflow and show available tests based on an end-users need. This project included: </p>

          <ul>
            <li>Heuristic evaluation, analysis and then finding opportunities for improvement</li>
            <li>Update the UI to match ASU brand standards</li>
          </ul>

          <h3>heuristic analysis</h3>

          <p><b>findings</b></p>
          <p>in an evaluation of the original tool, there were opportunities for improvement: 
            <ul>
                <li>Provide user the state of the tool</li>
                <li>Flatten to the workflow to make it easier for end users</li>
                <li>Make it more aesthetically pleasing</li>
            </ul>
          </p>

          
            

          <b>Personas</b>

          <p><i>Individuals</i>:  John Doe wants to travel internationally. He needs to provide a negative COVID test for immigration purposes.<br/><br/>
          <i>Businesses</i>:  Mary Joe is a small business owner that needs to test their employees everyday because they are essential workers<br/><br/></p>

          <p><i>Link to redesign</i> </p>

        <p><span  style={{backgroundColor: '#23213e', padding: '5px', borderRadius: '5px'}}><a href="https://xd.adobe.com/view/edf5c238-8cf5-43e2-b12f-ba9c8a2e0eb4-b8a6/?fullscreen" target="_blank">Redesign</a></span></p>
        

   
        </AccordionDetails>
      </Accordion>

      <p></p>

      <Accordion style={{backgroundColor: '#FFB400'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color: '#23213e'}}/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h2>medportal (2023)</h2>
        </AccordionSummary>
        <AccordionDetails>
          
        <img src={require('./MedPortal.png')} style={{width: '100%', height: '300px', objectFit: 'cover', borderRadius: '5px'}}/>
          <p><span  style={{backgroundColor: '#23213e', padding: '5px', borderRadius: '5px'}}><a href="https://magicmedportal.github.io/" target='_blank' id="accordian">Live Version</a></span></p>
          <p><b>Role</b>: UX Designer, UI Designer, Fullstack Developer<br/>
          <b>Team</b>: Master's Thesis <br/>
          <b>For</b>: School of Arts, Media and Engineering. Herberger School of Design, ASU </p>
          <blockquote>❝The goal of this project was to create a mechanism to create a kiosk for checkin and sign in. The overall idea was to create an centralized, low-cost system that would intake as much information as possible, and provide visibility for medical staff versus a traditional walk up method.❞</blockquote>

          <h3>problem statement</h3>

          <p>The pandemic saw a strain on the medical community. The goal of this tool was to use media synthesis (the intake and OCR processing of images), combined with open-source solutioning is a viable intake mechanism and a means to aggregate low-risk patients. However, there are some human determined elements in parsing data. Using text completion models, such as ChatGPT (used in this project), parsing made it possible. However, this requires precise prompt engineering and you do not get the same outcome twice.</p>

<p>The combination of OCR, and, facilitating patient intake would allow medical staff to focus on critical patients. However, this applied project found that it would increase the patient experience for check in. Criticality is still human deterministic and requires a large dataset for a classifier or recommendation model. </p>

<p><span  style={{backgroundColor: '#23213e', padding: '5px', borderRadius: '5px'}}><a href="https://magicmedportal.github.io/" target='_blank' id="accordian">View the Website to see the full experience</a></span></p>   

<p><span  style={{backgroundColor: '#23213e', padding: '5px', borderRadius: '5px'}}><a href="https://wellokaylisten.com/AppliedProject.pdf" target='_blank' id="accordian">Download the Thesis</a></span></p>   

        </AccordionDetails>
      </Accordion>

<p></p>

<Accordion style={{backgroundColor: '#FFB400'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{color: '#23213e'}} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h2>modlife</h2>
        </AccordionSummary>
        <AccordionDetails>
          
        <img src={require('./ModLife.png')} style={{width: '100%', height: '300px', objectFit: 'cover', borderRadius: '5px'}}/>
          <p><span  style={{backgroundColor: '#23213e', padding: '5px', borderRadius: '5px'}}><a href="https://sites.google.com/asu.edu/modlife/home" target='_blank' id="accordian">Live Version</a></span></p>
          <p><b>Role</b>: Graphic Designer, Contributing Artist<br/>
          <b>For</b>: School of Arts, Media and Engineering. Herberger School of Design, ASU </p>
          <blockquote>❝Once you have glimpsed the world as it might be, it is impossible to live anymore complacent in the world as it is.❞</blockquote>

          <h3>Pitch + World </h3>
<p>World Imagined: what if we had an abundance of space in the year 2068?<br/></p>

<blockquote>As technology proliferates, learning futures have evolved to a point where classes can be transferred online - leaving ASU Polytechnic campus with a surplus of space:  buildings, fields, land etc.  With the excess of space, Arizona State University opened a consortium to collaborate with the City of Mesa, a company called ModLife and several international institutions to revitalize the Polytechnic Campus to create a hyper-localized community.   </blockquote>

<p>We wanted to take what we had been researching, and have a tangible representation: application of science-fiction tropes, sustainable architecture, and personal aesthetics. How could we apply concepts that we had been researching in this class? </p>

<p>The best way was to create a fictional real estate company that came to implement these ideas.  Since the theme of Emerge 2022 was the future of food, we sought to explore how we can change the paradigm of community, spacial equity and showcase the relationship between community dynamics and food access.  </p>

<p>The Future of Food means being sustainable. Food is at the center of our communities: the way we gather, the way we celebrate, and the way we live. ModLife is an exhibit for the tiny home of the future, in the community of the future: how do we gather to eat together? How do we cook? How do make sure that our footprint is minimal but without sacrificing community? This is what ModLife seeks to explore.</p>

<h3>Concept</h3>
<p>The concept for the Emerge was to showcase a sample home in the world that we created for 2072.   <br/>

Applied Concepts:  </p>

<ul>

<li>Repurposing materials that aren't easily recycled </li>

<li>Modular furniture, modular materials </li>

<li>Accessibility and equitable design </li>

<li>Ambient sound of a community, and the importance of community </li>

<li>Sustainable concepts such as hydroponics, and closed-loop economies. </li>

</ul>

<p>Features: </p>

<ul>
    <li>19ft long x 9ft deep</li>

<li>Projected across the back wall, the house </li>

<li>3D Printed replica of the house </li>

<li>Brochures</li>

<li>Stickers </li></ul>


<h3>lookbook</h3>

<p><span  style={{backgroundColor: '#23213e', padding: '5px', borderRadius: '5px'}}><a href="https://magicmedportal.github.io/" target='_blank' id="accordian">View the Website to see the full experience</a></span></p>   



   
        </AccordionDetails>
      </Accordion>
      
      <h2>exhibits/presentations</h2>
       <ul>
        <li>December 2023 &#x21A0; Panelist // Smart Urban Gardens Workshop [@] Tempe, AZ</li>
        <li>November 2023  &#x21A0; Presentation // <a href="https://wellokaylisten.com/DataNarratives.pdf" target='_blank'>Importance of Data Narratives in Data Viusalization [@] 2023 ASU Data Conference</a></li>
        <li>November 2022  &#x21A0; Co-Artist & Class Project // ModLife: A Hyperlocal Community of the Future [@] 2022 Emerge Festival</li>
        <li>November 2022  &#x21A0; Presentation // <a href="https://wellokaylisten.com/UX.pdf" target='_blank'>UX in Data Visualization [@] 2022 ASU Data Conference</a></li>
        <li>November 2018  &#x21A0; Presentation // Effective Data Visualization Tools [@] 2018 ASU Data Conference</li>
       </ul>

    </div>
    </>
  );
}
