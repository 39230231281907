import './App.css';
import *  as React from 'react';
import Typewriter from "typewriter-effect";
import {ReactComponent as Avatar} from './images/avatar.svg';
import { HashLink } from 'react-router-hash-link';


export default function Home() { 
    return(                
 

          <div className='written'>
            <div style={{display: 'flex'}}>
              <div style={{float: 'left', display: 'flex'}} id="mobileHide"><Avatar id="mobileHide" style={{borderRadius: '50%', borderStyle: 'solid', borderColor: '#FFDB95', borderWidth: '10px'}}/></div>
              <div id="mobileDiv">
              
                <p id="header">hi there, i'm ishrat!</p> 

               <p>Hello there! My name is <b>Ishrat</b> (she/her/hers)!</p>
         
           <p>I'm an <b>experiential designer</b>.  I believe that design is more about how people interact with something, or view something, but rather <b>the journey that they're on</b> when they go through my work. I have a Masters degree in Media Arts and Sciences, and my research is focused on the ways that we interact with the world, and how worldbuilding and narratives shape those interactions. <b>tl;dr</b>: I design and research how we interact with the world and use that to enrich people's lives.</p>

           <HashLink smooth to={'/#about'}>Do you want to learn more about me?</HashLink>

           

              </div>
            </div>
  </div>        
)
}